<template>
    <div
        class="user-detail-section-item"
        :style="customStyle"
        :class="{ 'border-bottom': borderBottom, 'dark-mode': darkMode }"
    >
        <!-- <div class="user-detail-section-item"  :style="{ testStyle : testStyle, customStyle: customStyle }"> -->
        <div class="title-area m-b-12">{{ title }}</div>
        <div class="content-area flex-row" v-if="displayType === 'bar-seperated'">
            <div class="content bar-seperated" v-for="(item, idx) in sectionItem" :key="idx">{{ item }}</div>
        </div>
        <div class="content-area flex" v-else-if="displayType === 'key-value-pair'">
            <div
                class="content flex-row key-value"
                :class="{ 'align-column': item.align === 'column' }"
                v-for="(item, idx) in sectionItem"
                :key="idx"
            >
                <div class="content-key m-r-8">{{ item.key }}</div>
                <div class="content-value" v-html="item.value.replace(/\n/g, '<br>')" />
            </div>
        </div>
        <div class="content-area flex" v-else-if="displayType === 'lengthy-value'">
            <div class="content" v-html="sectionItem.replace(/\n/g, '<br>')" />
        </div>
        <hr v-if="borderBottom" />
    </div>
</template>

<script>
export default {
    name: 'UserDetailSectionItem',
    props: {
        title: {
            type: String,
        },
        displayType: {
            type: String,
        },
        customStyle: {
            type: Object,
        },
        borderBottom: {
            type: Boolean,
        },
        darkMode: {
            type: Boolean,
        },
        sectionItem: {
            type: [Array, Object, String],
        },
    },
    computed: {},
}
</script>
<style scoped lang="scss">
.user-detail-section-item {
    // &.border-bottom {
    //     border-bottom: 1px solid #ced5d9;

    // }
    // padding-bottom: 24px;
    hr {
        margin-top: 24px;
    }
    .title-area {
        // @include gmarket-sans-b;

        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    .content-area {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #070a0e;
        flex-wrap: wrap;
        .bar-seperated:not(:last-child)::after {
            content: '|';
            width: 10px;
            margin: 0 8px;
        }
        .content {
            width: fit-content;
            flex: none;

            .content-key {
                color: #94999e;
                width: fit-content;
                min-width: 105px;
                font-size: 15px;
            }
            .content-value {
                font-size: 15px;
            }
        }
    }
    .key-value:not(:last-child) {
        margin-bottom: 10px;
    }
    .align-column {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        .content-value {
            background: #f7f8f9;
            padding: 12px;
            width: 100%;
        }
    }
    &.dark-mode {
        background: #070a0e;
        & > * {
            color: #fff;
        }
    }
}
</style>
