<template>
    <div ref="userDetail" class="user-detail" @scroll="onScroll">
        <div class="header" :class="{ bg: backIcon === 'back' }">
            <img
                class="back"
                @click="back"
                :src="require(`@/assets/images/icons/${backIcon}.png`)"
                width="24px"
                height="24px"
            />
        </div>
        <img
            class="blind-profile-bg"
            id="blind-profile-bg"
            :src="require('@/assets/images/user-detail/blind_profile_bg.png')"
            alt=""
        />
        <!-- <div class="title-area section" id="blind-title">
            <div class="title">
                Detailed<br />
                Profile
            </div>
            <div class="logo">
                <img class="would-you" :src="require('@/assets/images/user-detail/would_you.png')" alt="" />
                <img class="marry-me" :src="require('@/assets/images/user-detail/marry_me.png')" alt="" />
            </div>
        </div> -->
        <div class="user-detail-section-wrapper section" id="blind-title">
            <UserDetailSectionItem
                v-if="personalProfile.length"
                :customStyle="customPadding"
                :title="'Personal'"
                :displayType="'bar-seperated'"
                :borderBottom="true"
                :sectionItem="personalProfile"
            />
            <UserDetailSectionItem
                :customStyle="customPadding"
                :title="'Career'"
                :displayType="'bar-seperated'"
                :borderBottom="true"
                :sectionItem="careerProfile"
                v-if="careerProfile.length"
            />
            <UserDetailSectionItem
                :customStyle="customPadding"
                :title="'Education'"
                :displayType="'bar-seperated'"
                :borderBottom="true"
                :sectionItem="educationProfile"
                v-if="educationProfile.length"
            />
            <UserDetailSectionItem
                :customStyle="customPadding"
                :title="'Financial'"
                :displayType="'key-value-pair'"
                :borderBottom="true"
                :sectionItem="financialProfile"
                v-if="financialProfile.length"
            />
            <UserDetailSectionItem
                :customStyle="customPadding"
                :displayType="'key-value-pair'"
                :borderBottom="false"
                :title="'Family'"
                :sectionItem="familyProfile"
                v-if="familyProfile.length"
            />
            <UserDetailSectionItem
                :customStyle="messagePadding"
                :title="'Message'"
                :displayType="'lengthy-value'"
                :darkMode="true"
                :sectionItem="this.profile.intro"
                v-if="this.profile.intro"
            />
        </div>

        <div class="button-area" v-if="content.accept_status === 'accepted'">
            <button class="accepted-btn">
                <img class="m-r-2" src="@/assets/images/icons/check.png" width="14px" height="14px" />

                수락되었습니다
            </button>
        </div>
        <div class="button-area" v-else-if="content.accept_status === 'rejected'">
            <button class="undo-btn" @click="onClickButton('undo')">
                <img class="m-r-2" src="@/assets/images/icons/refresh.png" width="24px" height="24px" />

                <div>되돌리기</div>
            </button>
        </div>
        <BottomButton
            v-else
            :blackBtn="true"
            class="my-profile-confirm"
            :doubleBtn="true"
            :doubleBtnsInfo="doubleBtnsInfo"
            :customStyle="bottomButtonStyle"
            :nonFixed="true"
        />
        <!-- <UserDetailHeaderBar
                :from="from"
                :user="user"
                :userId="userId"
                :photos="userPhotos"
                :show="showHiddenHeader"
                :nickname="user.profile.nickname"
                :customHeader="header"
                @open="openActionSheet"
            /> -->
    </div>
</template>

<script>
import chatService from '@/services/chat'

// import UserDetailHeaderBar from './components/UserDetailHeaderBar'

import UserDetailSectionItem from './components/UserDetailSectionItem'

export default {
    name: 'UserBlindProfilePage',
    components: {
        UserDetailSectionItem,
    },
    props: {
        from: String,
        userId: {
            type: Number,
            required: true,
        },
        agent: Object,
        agentId: Number,
        photos: {
            type: Array,
            default: () => [],
        },
        agentPhoto: String,
        agentMessage: String,
        profilePhotoMessage: Object,
        status: String,
        header: {
            type: Object,
        },
        message: {
            type: Object,
        },
        chat: {
            type: Object,
        },
    },
    data: () => ({
        user: null,
        showHiddenHeader: false,
        loading: false,
        userPhotos: [],
        profileConfirmed: false,
        vipBottom: 0,
        backIcon: 'back_white',
    }),
    watch: {
        userId: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.init()
            }
        },
    },
    created() {
        this.init()
    },
    mounted() {},
    computed: {
        profile() {
            return this.user.profile || {}
        },
        content() {
            return this.$mustParse(this.profilePhotoMessage.content) || {}
        },
        personalProfile() {
            if (!this.user) return
            const profileItem = []
            const age = this.profile.birthday ? this.$options.filters.asAge(this.profile.birthday) : null
            const region = this.profile.region_shorten ? this.profile.region_shorten : null
            if (age) profileItem.push(age)
            if (region) profileItem.push(region)
            return profileItem
        },

        careerProfile() {
            if (!this.user) return
            const profileItem = []
            const career = ['job_name', 'company_name']
            career.forEach(key => {
                if (((this.profile.company || {})[0] || [])[key]) {
                    const value = ((this.profile.company || {})[0] || [])[key]
                    profileItem.push(value)
                }
            })

            return profileItem
        },

        educationProfile() {
            if (!this.user) return
            const profileItem = []
            const univ = [
                {
                    key: 'bachelor',
                    label: '학사',
                },
                {
                    key: 'master',
                    label: '석사',
                },
                {
                    key: 'doctor',
                    label: '박사',
                },
            ]
            univ.forEach(u => {
                if (this.profile[`${u.key}_u_name`]) {
                    profileItem.push(`${this.profile[`${u.key}_u_name`]}(${u.label})`)
                }
            })
            return profileItem
        },

        financialProfile() {
            if (!this.user) return
            const profileItem = []
            const income = {
                key: '연소득',
                value: this.$krwParsed(this.profile.income) || '소득없음',
            }
            const asset = {
                key: '보유자산',
                value: this.$krwParsed(this.profile.asset) || '자산없음',
            }
            const assetDetail = {
                key: '보유 자산 상세',
                value: this.profile.asset_detail,
                align: 'column',
            }
            const finance = {
                key: '재테크',
                value: this.profile.finance,
                align: 'column',
            }

            const car = {
                key: '차량',
                value: `${this.profile.car_type ? this.profile.car_type + ' ' : ''}${
                    this.profile.car_model ? `(${this.profile.car_model})` : ''
                }`,
            }
            const financial = [income, asset, assetDetail, finance, car]
            financial.forEach(item => {
                if (item.value) {
                    profileItem.push(item)
                }
            })
            return profileItem
        },
        familyProfile() {
            if (!this.user) return
            const profileItem = []
            const familyAsset = {
                key: '집안자산',
                value: this.$krwParsed(this.profile.family_asset) || '자산없음',
            }
            const familyIntro = {
                key: '가족소개',
                value: this.profile.family_intro,
                align: 'column',
            }
            const family = [familyAsset, familyIntro]
            family.forEach(item => {
                if (item.value) {
                    profileItem.push(item)
                }
            })
            return profileItem
        },

        customPadding() {
            return {
                'box-sizing': 'border-box',
                padding: '24px 24px 0 ',
            }
        },
        doubleBtnsInfo() {
            const pass = {
                style: {
                    border: '1px solid #FFFFFF',
                    borderRadius: '4px',
                    backgroundColor: '#070A0E',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '26px',
                    textAlign: 'center',
                    color: ' #FFFFFF',
                },
                title: '패스',
                click: () => this.onClickButton('rejected'),
            }
            const accept = {
                style: {
                    border: 'none',
                    borderRadius: '4px',
                    backgroundColor: '#03006E',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '26px',
                    textAlign: 'center',
                    color: ' #FFFFFF',
                },
                title: '수락',
                click: () => this.onClickAccept(),
            }

            return [pass, accept]
        },
        acceptedButtonStyle() {
            return {
                backgroundColor: '#070A0E',
                // borderRadius: '4px',
                fontSize: '16px',
                lineHeight: '26px',
                color: '#FFFFFF',
                padding: '0 24px 36px',
                // background: 'rgba(241, 255, 252, 0.2)',
            }
        },
        messagePadding() {
            return {
                marginTop: '60px',
                padding: '60px 24px 40px',
            }
        },
        bottomButtonStyle() {
            return {
                backgroundColor: '#070A0E',
                padding: '0 24px 36px',
            }
        },

        remainProfilePhotoMessages() {
            if (!this.fromProfilePhotoComponent) return []
            return (this.$store.getters.chat.$$messages || [])
                .filter(message => {
                    if (message.mtype !== 'profile-photo' || this.profilePhotoMessage.id >= message.id) {
                        return false
                    }
                    const content = this.$mustParse(message.content)
                    // show_photo 는 undefined 이거나 false 임
                    return !content.opened && !content.accept_status && content.show_photo === undefined
                })
                .reverse()
        },
    },
    methods: {
        async init() {
            if (!this.userId) {
                this.$stackRouter.pop()
                return
            }
            this.loading = true
            try {
                this.user = await this.$store.dispatch('loadBlindUserDetail', { userId: this.userId })
            } catch (e) {
                this.$toast.error(e.data)
                this.$stackRouter.pop()
                return
            } finally {
                this.loading = false
            }
        },
        async onClickAccept() {
            const alert = await this.$modal.basic({
                body: '소개 받으시겠어요?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (alert) {
                this.onClickButton('accepted')
            }
        },
        async onClickButton(status) {
            try {
                const chat = this.$store.getters.chat
                await chatService.acceptProfile(chat.id, this.profilePhotoMessage.id, status)

                const message = chat.$$messages.find(message => message.id === this.profilePhotoMessage.id)
                const content = this.$mustParse(message.content)

                if (status === 'undo') delete content.accept_status
                else {
                    content.accept_status = status
                }

                message.content = JSON.stringify(content)

                if (this.remainProfilePhotoMessages.length > 0) {
                    const nextMessage = this.remainProfilePhotoMessages[0]
                    const content = this.$mustParse(nextMessage.content)

                    await chatService.openProfile(this.$store.getters.chat.id, nextMessage.id)
                    content.opened = true
                    const message = this.$store.getters.chat.$$messages.find(message => message.id === nextMessage.id)
                    message.content = JSON.stringify(content)
                }
                // this.$stackRouter.replace({
                //     name: 'UserDetailPage',
                //     props: {
                //         userId: content.source_id,
                //         photos: photos(),
                //         from: 'ProfilePhoto',
                //         profilePhotoMessage: nextMessage,
                //     },
                // })

                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        back() {
            this.$stackRouter.pop()
        },
        onScroll(event) {
            const title = document.getElementById('blind-title')

            if (event.target.scrollTop >= title.offsetTop - 50) {
                this.backIcon = 'back'
            } else {
                this.backIcon = 'back_white'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.user-detail {
    overflow-y: auto;
    // position: relative;
    .header {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        height: 50px;
        z-index: 2;
        .back {
            top: 14px;
            left: 14px;
            position: absolute;
        }
        &.bg {
            background: #fff;
        }
    }
    .blind-profile-bg {
        position: relative;
        width: 100%;
        // height: 50%;
        object-fit: cover;
    }
    .section {
        // padding: 0 24px;
        position: relative;
    }
    .user-detail-section-wrapper {
        margin-top: 36px;
        // margin-bottom: 80px;
    }
    .title-area {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 130px;

        .title {
            padding: 0 24px;
            // @include gmarket-sans-b;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #000000;
        }
        .logo {
            height: 100%;
            margin-right: 24px;
            display: flex;
            .would-you {
                align-self: flex-end;
            }
            .marry-me {
                align-self: flex-start;
            }
        }
    }

    hr {
        border-bottom: solid 1px $grey-02;
    }
    .button-area {
        background: #070a0e;
        padding: 0 24px 36px;
        .accepted-btn {
            font-weight: 400;
            padding: 12px 10px;
            width: 100%;
            height: 50px;
            background: rgba(241, 255, 252, 0.2);
            border-radius: 4px;
            border: none;
            color: #ffffff;
        }
        .undo-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 12px 10px;
            height: 50px;
            border: 1px solid #ffffff;
            border-radius: 4px;
            background: #070a0e;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #ffffff;
        }
    }
}
</style>
